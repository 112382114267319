(function() {

    if (process.env.NODE_ENV == 'development') {
        return;
    }

    var element = document.getElementById("payload-dashboard-widget");
    if (!element) {

        element = document.createElement('div');
        element.id = 'payload-dashboard-widget';

        var parent = document.createElement('div');
        parent.id = 'payload-dashboard-widget-hidden';
        parent.style.cssText = 'display: none';
        parent.appendChild(element);
        document.body.appendChild(parent);
    }

    

    // Application
    // loadjscssfile(__webpack_public_path__ + 'css/chunk-vendors.css');
    loadjscssfile(__webpack_public_path__ + 'css/app.css');
    loadjscssfile(process.env.VUE_APP_API_URL + '/wha/' + location.hostname + '/theme.css'); // wha theming

    loadjscssfile(process.env.VUE_APP_API_URL + '/wha/' + location.hostname + '/config.js'); // JS config
    loadjscssfile(__webpack_public_path__ + 'js/chunk-vendors.js');
    loadjscssfile(__webpack_public_path__ + 'js/app.js');

    function loadjscssfile(filename) {
        var filetype = filename.split('.').pop();

        if (filetype == "js") {
            fileref = document.createElement('script')
            fileref.setAttribute("type", "text/javascript")
            fileref.setAttribute("src", filename + '?v=' + __webpack_hash__)
        } else if (filetype == "css") {
            fileref = document.createElement("link")
            fileref.setAttribute("rel", "stylesheet")
            fileref.setAttribute("type", "text/css")
            fileref.setAttribute("href", filename + '?v=' + __webpack_hash__)
        }
        if (typeof fileref != "undefined") {
            document.getElementsByTagName("head")[0].appendChild(fileref)
        }
    }

})();